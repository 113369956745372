import React from 'react';
import './App.css';

function App() {
  return (
      <div className="App" style={{ position: 'relative', height: '100vh', width: '100vw' }}>
          <iframe 
              title="Dogrula"
              src="https://www.dogrula.org" 
              style={{ 
                  position: 'absolute', 
                  top: 0, left: 0, 
                  height: '100%', width: '100%', 
                  border: 0 
              }}>
              Bu tarayıcı iframe'leri desteklemiyor.
          </iframe>
      </div>
  );
}

export default App;